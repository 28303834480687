import { siteMetaData } from './sites'

export type SeoConfig = {
  path: string | null
  title?: string
  description?: string
  imageUrl?: string
  children?: React.ReactNode
}

type PropsType = SeoConfig & {
  Wrapper: React.FC<{ children: React.ReactNode }>
  origin: string
}

// The Open Graph protocol
// https://ogp.me/

// Twitter for Website
// https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
const Seo = (props: PropsType) => {
  const { Wrapper, imageUrl, origin, path } = props

  const title = props.title ?? siteMetaData.title

  const description = props.description || siteMetaData.description

  // Support both svg and fall back to png if not support
  // https://catalin.red/svg-favicon-light-dark-theme/#browser-support-and-fallbacks
  return (
    <Wrapper>
      <title key="title">{title}</title>
      <meta key="description" name="description" content={description} />
      <meta key="keywords" name="keywords" content={siteMetaData.keywords} />
      <link
        key="faviconSvg"
        rel="icon"
        href="/favicon.svg"
        type="image/svg+xml"
      />
      <link
        key="faviconPng"
        rel="icon"
        href="/favicon-512x512.png"
        type="image/png"
      />
      <meta key="og:title" property="og:title" content={title} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="og:site_name"
        property="og:site_name"
        content={siteMetaData.name.full}
      />
      <meta
        key="og:image"
        property="og:image"
        content={new URL(imageUrl ?? '/open-graph-house-cg.png', origin).href}
      />

      {path && (
        <meta
          key="og:url"
          property="og:url"
          content={new URL(path, origin).href}
        />
      )}

      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta key="twitter:card" name="twitter:card" content="summary" />
      {props.children}
    </Wrapper>
  )
}

export default Seo
