import * as React from 'react'
import { Seo, SeoConfig } from 'ui'

export default (props: SeoConfig) => {
  return (
    <Seo
      Wrapper={React.Fragment}
      origin={process.env.GATSBY_PUBLIC_BLOGS_ORIGIN ?? ''}
      {...props}
    />
  )
}
