import { PageProps } from 'gatsby'
import { ApplicationError, NotFoundError } from 'layouts'
import Seo from 'components/Seo'
import { Container, Spacer, Text } from '@nextui-org/react'

const ErrorMessage = ({ err }: { err: ApplicationError }) => {
  return (
    <div className="flex justify-center items-center">
      <Text
        size={36}
        className="border-0 border-r border-solid mr-5 pr-5"
        css={{
          borderColor: '$border',
        }}
      >
        {err.status}
      </Text>
      <Text size="1.25rem">{err.message}</Text>
    </div>
  )
}

export default function NotFound(props: PageProps<{}>) {
  const err = NotFoundError()

  return (
    <>
      <Seo path={null} title={err.message} />
      <Container>
        <Spacer y={10} />
        <ErrorMessage err={err} />
      </Container>
    </>
  )
}
